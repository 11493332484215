import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import { createDateFormatter } from 'flatpickr/dist/esm/utils/dates';

require('./styles/date_picker.css');
require('./styles/date_picker_month_select.css');

const CALENDAR_DATE_SEARCH_PARAM = 'start_date';
const ALT_DATE_FORMAT = 'M j, Y';

type Select = 'month' | 'week';

const defaultFormatDate: (dateObj: Date, frmt: string, locale: flatpickr.Locale) => string = createDateFormatter({});

export default class extends Controller {
  /*
    this controller is for enabling copying to clipboard
  */

  picker: flatpickr.Instance;
  currentSelections: [dates: Date[], currentDateString: string, self: flatpickr.Instance, data?: unknown];

  connect() {
    const minDate = this.data.get('minDate');
    const defaultDateStart = this.data.get('defaultDateStart') ? new Date(this.data.get('defaultDateStart')) : null;
    const defaultDate = new Date(this.data.get('defaultDate'));
    const select: Select = this.data.get('select') as Select;
    let mode: flatpickr.Options.Options['mode'] = 'single';
    let defaultDateRange = null;

    // we want Date's, not DateTimes, but JS only has DateTimes in "Dates"
    // so when we create the Date in UTC, it updates it to the local time zone
    // so we have to get the offset, and adjust it back since flatpickr uses
    // the local time on the Date, not the UTC time, and there's no way to tell it to
    const localTimeZoneOffset = defaultDate.getTimezoneOffset() * 60000;
    const adjustedDefaultDate = new Date(defaultDate.getTime() + localTimeZoneOffset);
    if (defaultDateStart) {
      const adjustedDefaultDateStart = new Date(defaultDateStart.getTime() + localTimeZoneOffset);
      defaultDateRange = [adjustedDefaultDateStart, adjustedDefaultDate];
    }

    const plugins: flatpickr.Options.Plugin[] = [];
    if (select === 'month') {
      plugins.push(monthSelectPlugin({}));
    } else if (select === 'week') {
      plugins.push(weekSelectPlugin());
    } else if (select === 'range') {
      mode = 'range';
    }

    this.picker = flatpickr(this.element, {
      onChange: (...newSelections) => {
        this.currentSelections = newSelections;
      },
      formatDate(date, format, locale) {
        if (format === ALT_DATE_FORMAT && select === 'week') {
          const startDate = new Date(date);
          startDate.setDate(startDate.getDate() - startDate.getDay()); // set start to beginning of the week

          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6); // set end date to last day of the week

          return `${defaultFormatDate(startDate, format, locale)} – ${defaultFormatDate(endDate, format, locale)}`;
        }
        return defaultFormatDate(date, format, locale);
      },
      altInput: true,
      altFormat: ALT_DATE_FORMAT,
      defaultDate: defaultDateRange || adjustedDefaultDate,
      minDate,
      plugins,
      mode,
    });
  }

  disconnect() {
    this.picker.destroy();
  }

  get formatedCurrentDate(): `${number}-${number}-${number}` {
    const currentDates = this.currentSelections[0];
    const date = currentDates[0];
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  navigate() {
    const url = new URL(window.location.href);
    // right now, only used for calendar pages
    url.searchParams.set(CALENDAR_DATE_SEARCH_PARAM, this.formatedCurrentDate);
    window.location.assign(url.toString());
  }
}
